<template>
  <el-dialog
    :title="title"
    v-model="visible"
    width="700px"
    draggable
    @closed="onClosed"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="12">
          <el-input v-model="form.name"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="本司处理人数" prop="userNum"> 
        <el-col :span="8">
          <el-input v-model="form.userNum"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="分销处理人数" prop="mktgNum"> 
        <el-col :span="8">
          <el-input v-model="form.mktgNum"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="分类区分">
        <el-checkbox  v-model="form.isDifferentiate" :label="true">是</el-checkbox>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="visible = false" :disabled="saving">取消</el-button>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      saving: false,
      visible: false,
      title: "",
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  props: {
    item: Object,
  },
  emits: ["closed", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
          this.title = this.item.id ? "修改" : "新增";
          this.visible = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onClosed() {
      this.$emit("closed");
    },

    /**
     *
     */
    onSubmit() {
        this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/estateAttribute/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.$emit("success");
                this.visible = false;
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style scoped></style>
